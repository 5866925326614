import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { queryClient } from 'lib/queryClient';
import { updateProfileInfo } from 'api/profile';

import { IUpdateProfileRequestParams } from 'types/api/profile';

interface UseUpdateProfileOptions
  extends Omit<
    UseMutationOptions<string, unknown, IUpdateProfileRequestParams>,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: string) => void;
  onError?: (error: unknown) => void;
}

type UseUpdateProfileHook = (
  options?: UseUpdateProfileOptions,
) => UseMutationResult<string, unknown, IUpdateProfileRequestParams>;

export const useMutateUpdateProfileInfo: UseUpdateProfileHook = options => {
  return useMutation(updateProfileInfo, {
    ...options,
    onSuccess: () => {
      toast.success('Success');
      queryClient.invalidateQueries(['fetchProfileInfo']);
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while updating Profile';

      console.log(message);

      return message;
    },
  });
};

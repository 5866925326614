// core
import React from 'react';
import { Layout as AntLayout } from 'antd';

// utils

// locales

// store

// types

// components(atoms)
import Header from '../../features/Header';
import Footer from '../../features/Footer';
import Sidebar from '../../features/Sidebar';

// styles
import styles from './index.module.scss';

const { Content } = AntLayout;

// func
const StaticLayout = ({ children }: any) => {
  return (
    <AntLayout className={styles.layout}>
      <Sidebar />
      <AntLayout className={styles.mainLayout}>
        <Header />
        <Content className={styles.content}>{children}</Content>
        <Footer />
      </AntLayout>
    </AntLayout>
  );
};

export default StaticLayout;

import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
export enum EButtonDimensionsType {
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
}

export enum EButtonStyleType {
  Fill = 'fill',
  Outline = 'outline',
}

export enum EButtonTypeType {
  Button = 'button',
  Submit = 'submit',
}

export interface IPropsType extends ComponentPropsWithoutRef<'button'> {
  dimension?: EButtonDimensionsType;
  stylType?: EButtonStyleType;
  type: EButtonTypeType;
  children: ReactNode | null;
  prependIcon?: React.ReactElement | null;
  appendIcon?: React.ReactElement | null;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

import React from 'react';
import { Table, Button, Input, Select, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { StockDetailsSEO } from './SEO';

const { Search } = Input;
const { Option } = Select;

const data = [
    {
      key: '1',
      refNo: 'poplin',
      description: 'Reflex Poplin',
      color: 'White',
      customerName: 'Not Designated',
      lastOrderDate: '2024-09-03',
      lastOrderQty: 55,
      lastOrderRemaining: 55,
      stock: 1015,
      stockUnit: 'Yards',
      lastUpdate: '09-09-2024',
    },
    {
      key: '2',
      refNo: 'cotton',
      description: 'Cotton Classic',
      color: 'Blue',
      customerName: 'Design Pro',
      lastOrderDate: '2024-09-01',
      lastOrderQty: 100,
      lastOrderRemaining: 45,
      stock: 2000,
      stockUnit: 'Meters',
      lastUpdate: '09-09-2024',
    },
    {
      key: '3',
      refNo: 'denim',
      description: 'Denim Standard',
      color: 'Black',
      customerName: 'Creative Textiles',
      lastOrderDate: '2024-08-28',
      lastOrderQty: 75,
      lastOrderRemaining: 75,
      stock: 1500,
      stockUnit: 'Yards',
      lastUpdate: '09-09-2024',
    },
    {
      key: '4',
      refNo: 'silk',
      description: 'Luxury Silk',
      color: 'Red',
      customerName: 'Fashion Hub',
      lastOrderDate: '2024-09-05',
      lastOrderQty: 20,
      lastOrderRemaining: 10,
      stock: 500,
      stockUnit: 'Meters',
      lastUpdate: '09-09-2024',
    },
    {
      key: '5',
      refNo: 'linen',
      description: 'Soft Linen',
      color: 'Beige',
      customerName: 'Style Masters',
      lastOrderDate: '2024-08-30',
      lastOrderQty: 60,
      lastOrderRemaining: 40,
      stock: 800,
      stockUnit: 'Yards',
      lastUpdate: '09-09-2024',
    },
    {
      key: '6',
      refNo: 'wool',
      description: 'Warm Wool',
      color: 'Gray',
      customerName: 'Not Designated',
      lastOrderDate: '2024-09-02',
      lastOrderQty: 90,
      lastOrderRemaining: 35,
      stock: 600,
      stockUnit: 'Meters',
      lastUpdate: '09-09-2024',
    },
    {
      key: '7',
      refNo: 'velvet',
      description: 'Royal Velvet',
      color: 'Purple',
      customerName: 'Luxury Designs',
      lastOrderDate: '2024-09-06',
      lastOrderQty: 30,
      lastOrderRemaining: 25,
      stock: 300,
      stockUnit: 'Yards',
      lastUpdate: '09-09-2024',
    },
  ];

const columns = [
  {
    title: 'S.No',
    dataIndex: 'key',
    key: 'key',
    width: 60,
  },
  {
    title: 'Ref No',
    dataIndex: 'refNo',
    key: 'refNo',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Color',
    dataIndex: 'color',
    key: 'color',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
    render: text => (
      <span style={{ color: text === 'Not Designated' ? 'red' : 'inherit' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Last Order Date',
    dataIndex: 'lastOrderDate',
    key: 'lastOrderDate',
  },
  {
    title: 'Last Order Qty',
    dataIndex: 'lastOrderQty',
    key: 'lastOrderQty',
  },
  {
    title: 'Last Order Remaining Qty',
    dataIndex: 'lastOrderRemaining',
    key: 'lastOrderRemaining',
  },
  {
    title: 'Orders',
    key: 'orders',
    render: () => <Button>Purchases</Button>,
  },
  {
    title: 'Stock',
    dataIndex: 'stock',
    key: 'stock',
    render: (text, record) => `${text} ${record.stockUnit}`,
  },
  {
    title: 'Stock Update',
    key: 'stockUpdate',
    render: () => <Input style={{ width: 80 }} suffix={<span>Yards</span>} />,
  },
  {
    title: 'Last Update',
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <span>
        <Tooltip title="Edit">
          <Button icon={<EditOutlined />} className={styles.actionButton} />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            className={styles.actionButton}
            danger
          />
        </Tooltip>
      </span>
    ),
  },
];

const StockDetails = () => {
  return (
    <>
      <StockDetailsSEO />
      <div className={styles.container}>
        <h1 className={styles.title}>Manage Material Stock</h1>
        <div className={styles.controls}>
          <Select defaultValue="selectVendor" style={{ width: 200 }}>
            <Option value="selectVendor">Select Vendor</Option>
          </Select>
          <Select defaultValue="selectCompany" style={{ width: 200 }}>
            <Option value="selectCompany">Select Company</Option>
          </Select>
          <Search
            placeholder="Ref No [or] Fabric Name"
            style={{ width: 250 }}
          />
          <Button>Assign to Customer</Button>
          <Button type="primary" icon={<PlusOutlined />}>
            Add New Material
          </Button>
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className={styles.table}
          scroll={{ x: true }}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `Total ${total} items`,
          }}
        />
      </div>
    </>
  );
};

export default StockDetails;

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { changePassword } from 'api/profile';

import { IChangePasswordRequestParams } from 'types/api/profile';

interface UseChangePasswordOptions
  extends Omit<
    UseMutationOptions<string, unknown, IChangePasswordRequestParams>,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: string) => void;
  onError?: (error: unknown) => void;
}

type UseChangePasswordHook = (
  options?: UseChangePasswordOptions,
) => UseMutationResult<string, unknown, IChangePasswordRequestParams>;

export const useMutateChangePassword: UseChangePasswordHook = options => {
  return useMutation(changePassword, {
    ...options,
    onSuccess: (data: string) => {
      toast.success('Success');
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while Changing Password';

      console.log(message);

      return message;
    },
  });
};

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';

// utils

// locales

// store

// types
import { ERoleType } from 'types/models/role';
import { EMediaSizeType } from 'types/models/mediaSize';

// components(atoms)
import Aside from 'components/features/Aside';
import Header from 'components/features/Header';
import Footer from 'components/features/Footer';

// styles
import styles from './index.module.scss';

// data

// func
export default function UserLayout({ children }) {
  // hooks
  const { width: windowWidth } = useWindowSize();

  let profile = {
    role: 'user',
  };

  if (profile?.role === ERoleType.User) {
    return (
      <div className={styles.wrapper}>
        {windowWidth >= EMediaSizeType.S1024 && <Aside />}

        <div className={styles.inner}>
          <Header />
          <div className={styles.inner__wrapper}>
            <div className={styles.content}>{children}</div>
          </div>

          <Footer />
        </div>
      </div>
    );
  } else {
    return <Navigate to={'/'} />;
  }
}

// core
import React, { useEffect } from 'react';
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Typography, Button, Input, Spin } from 'antd';

// hooks
import { useMutateUpdateProfileInfo } from 'hooks/profile/useMutateUpdateProfileInfo';

// store
import useProfileStore from 'store/profile';

// locales
import { useTranslation } from 'react-i18next';

// types
import { EButtonTypeType } from 'components/atoms/Button/types';
import { IFormValuesType } from './types';

// components(atoms)
import { UpdateProfileSEO } from './SEO';
import Loader from '../../../components/features/Loader';
// import Button from '../../../components/atoms/Button';

// data
import { updateProfileValidation } from './data';

// styles
import styles from './index.module.scss';

const { Text, Title } = Typography;

const UpdateProfile = () => {
  // router hook
  const location = useLocation();
  const query = useUrlQuery();
  const navigate = useNavigate();
  // locales hook
  const { t } = useTranslation();

  //store
  const { profile } = useProfileStore();
  //api hooks
  const { mutate, isLoading, isSuccess } = useMutateUpdateProfileInfo();

  const onUpdateProfileHandler = (payload: IFormValuesType) => {
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/user/profile');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <UpdateProfileSEO />
      <div className={styles.wrapper}>
        <Title level={4}>Update Profile</Title>
        {isLoading && <Spin />}
        <Formik
          initialValues={{
            email: profile?.data.email || '',
            name: profile?.data.profile.name || '',
            lastName: profile?.data.profile.lastName || '',
            phone: profile?.data.profile.phone || '',
          }}
          onSubmit={onUpdateProfileHandler}
          validationSchema={updateProfileValidation}
        >
          {({ errors, touched, setFieldValue, values, handleChange }) => (
            <Form className={styles.form}>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Email</Text>
                  <Field
                    value={values.email}
                    component={Input}
                    onChange={e => setFieldValue('email', e.target.value)}
                    type="text"
                    autoComplete="chrome-off"
                    name="email"
                    className={[
                      styles.form__itemField,
                      errors.email && touched.email ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="email" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Name</Text>
                  <Field
                    value={values.name}
                    component={Input}
                    onChange={e => setFieldValue('name', e.target.value)}
                    type="text"
                    autoComplete="chrome-off"
                    name="name"
                    className={[
                      styles.form__itemField,
                      errors.name && touched.name ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="name" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Last Name</Text>
                  <Field
                    value={values.lastName}
                    component={Input}
                    onChange={e => setFieldValue('lastName', e.target.value)}
                    type="text"
                    autoComplete="chrome-off"
                    name="lastName"
                    className={[
                      styles.form__itemField,
                      errors.lastName && touched.lastName ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="lastName" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Phone</Text>
                  <Field
                    value={values.phone}
                    component={Input}
                    onChange={e => setFieldValue('phone', e.target.value)}
                    type="text"
                    autoComplete="chrome-off"
                    name="phone"
                    className={[
                      styles.form__itemField,
                      errors.phone && touched.phone ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="phone" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <Button type="primary" htmlType={EButtonTypeType.Submit}>
                Update
              </Button>{' '}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UpdateProfile;

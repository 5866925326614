import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { IProfile } from 'types/models/profile';

type ProfileState = {
  profile: IProfile | null;
  setProfile: (payload: IProfile) => void;
};

const useProfileStore = create(
  devtools(
    persist<ProfileState>(
      set => ({
        profile: null,
        setProfile: (payload: IProfile) => {
          set(state => ({
            profile: payload,
          }));
        },
      }),
      { name: 'profile' },
    ),
  ),
);
export default useProfileStore;

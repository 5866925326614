// core
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';
// import { stripHtml } from '../../utils/commons';

import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

// utils
import { $api } from 'utils/request';

// hooks
import { useMutateLogout } from 'hooks/auth/useMutateLogout';
import useAccessTokenStore from 'store/accessToken';
import { postRefreshToken } from 'api/refreshToken';

// hook
const useHttpClient = (
  httpClientInstance: AxiosInstance,
): [string | null, () => void] => {
  //local error state
  const [error, setError] = useState<string | null>(null);

  // translation hook
  const { i18n } = useTranslation();

  // const { mutate: logout } = useMutateLogout();
  const { setAccessToken, removeAccessToken } = useAccessTokenStore();

  // actions
  // const dispatch = useDispatch();
  // const onLogout = () => dispatch(logoutActions.fetchDataTrigger());

  // request interceptor
  const reqInterceptor = httpClientInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      setError(null);

      // formatting data
      if (config.data) {
        config.data =
          config.data instanceof FormData
            ? config.data
            : snakeCaseKeys(config.data, { deep: true });
      }

      // formatting headers
      const accessToken = localStorage.getItem('access_token');

      if (config.headers) {
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        // utm tags
        const utmSource = sessionStorage.getItem('utm_source');
        const utmMedium = sessionStorage.getItem('utm_medium');
        const utmCampaign = sessionStorage.getItem('utm_campaign');

        utmSource && (config.headers['X-UTM-SOURCE'] = utmSource);
        utmMedium && (config.headers['X-UTM-MEDIUM'] = utmMedium);
        utmCampaign && (config.headers['X-UTM-CAMPAIGN'] = utmCampaign);

        config.headers['X-localization'] =
          i18n.language && i18n.language.length > 2
            ? i18n.language.slice(0, 2)
            : i18n.language;
      }
      return config;
    },
    (error: AxiosError) => {
      let formattedMessage: string = JSON.stringify(error.response?.data);
      setError(formattedMessage);

      return Promise.reject(error);
    },
  );

  // response interceptor
  const resInterceptor = httpClientInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      // let data = res.status ? res.data : res;
      return camelCaseKeys(res, { deep: true });
    },
    async error => {
      let formattedMessage: string | null = null;

      const originalRequest = error.request;
      const requestConfig = error.config;

      if (error?.response) {
        // errors handling

        if (
          error.response?.status === 401 &&
          requestConfig &&
          !originalRequest.retry
        ) {
          try {
            originalRequest.retry = true;
            const data = await postRefreshToken();
            localStorage.setItem('access_token', data.accessToken);
            setAccessToken(data.accessToken);
            const updatedConfig = {
              ...requestConfig,
              data: error?.config?.data
                ? JSON.parse(error.config.data)
                : undefined,
            };

            return $api(updatedConfig);
          } catch (err) {
            console.log('catch', err);
            removeAccessToken();
          }
        }

        if (error.response.data?.message) {
          formattedMessage = error.response.data.message;
        }

        if (error.response.data?.error?.message) {
          formattedMessage = error.response.data?.error?.message;
        }

        if (error.response.data?.errors) {
          formattedMessage = '';
          Object.keys(error.response.data.errors).forEach(errorKey => {
            error.response.data.errors[errorKey].forEach((line: string) => {
              formattedMessage += `\r\n${line}`;
            });
          });
        }

        // if (error.response.data) {
        //   formattedMessage = stripHtml(error.response.data);
        // }
      }
      setError(formattedMessage);

      return Promise.reject(error);
    },
  );

  // watch reqInterceptor & resInterceptor - eject request & response interceptors
  useEffect(
    () => () => {
      httpClientInstance.interceptors.request.eject(reqInterceptor);
      httpClientInstance.interceptors.response.eject(resInterceptor);
    },
    [
      reqInterceptor,
      resInterceptor,
      httpClientInstance.interceptors.request,
      httpClientInstance.interceptors.response,
    ],
  );

  const errorClearedHandler = () => {
    setError(null);
  };

  return [error, errorClearedHandler];
};

export default useHttpClient;

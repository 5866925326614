// core
import React, { useEffect, useState } from 'react';
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Typography, Button, Input, Spin } from 'antd';

// hooks
import { useMutateRestorePassword } from 'hooks/auth/useMutateRestorePassword';

// locales
import { useTranslation } from 'react-i18next';

// store

// types
import { EButtonTypeType } from 'components/atoms/Button/types';
import { IFormValuesType } from './types';

// components(atoms)
import { RestorePasswordSEO } from './SEO';
// import Loader from '../../../components/features/Loader';
// import Button from '../../../components/atoms/Button';

// styles
import styles from './index.module.scss';

// data
import { initialData, restoreValidation } from './data';

const { Text, Title } = Typography;

// func
export default function Restore() {
  // router hook
  const location = useLocation();
  const query = useUrlQuery();
  const navigate = useNavigate();

  // locales hook
  const { t } = useTranslation();
  //api hooks
  const { mutate, isLoading, isSuccess } = useMutateRestorePassword();

  // local data
  const initialValues: IFormValuesType = initialData;

  const onRestoreHandler = payload => {
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/home');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // render
  return (
    <>
      <RestorePasswordSEO />
      <div className={styles.wrapper}>
        {isLoading && <Spin />}

        <Title level={1} className={styles.header__label}>
          Restore
        </Title>

        <Formik
          initialValues={initialValues}
          onSubmit={onRestoreHandler}
          validationSchema={restoreValidation}
        >
          {({ errors, touched, setFieldValue, values, handleChange }) => (
            <Form className={styles.form}>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Password</Text>
                  <Field
                    component={Input}
                    placeholder="enter password"
                    type="password"
                    autoComplete="chrome-off"
                    name="password"
                    onChange={e => setFieldValue('password', e.target.value)}
                    className={[
                      styles.form__itemField,
                      errors.password && touched.password ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="password" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Password Confirmation</Text>
                  <Field
                    component={Input}
                    placeholder="enter password again"
                    type="password"
                    autoComplete="chrome-off"
                    name="passwordConfirmation"
                    onChange={e =>
                      setFieldValue('passwordConfirmation', e.target.value)
                    }
                    className={[
                      styles.form__itemField,
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                        ? 'error'
                        : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage
                  name="passwordConfirmation"
                  className={styles.form__errors}
                >
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <Button type={'primary'} htmlType={EButtonTypeType.Submit}>
                Restore
              </Button>{' '}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

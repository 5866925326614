import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type {
  IRestorePasswordRequestParams,
  IRestorePasswordResponse,
} from 'types/api/auth';

export const postRestorePassword = async (
  params: IRestorePasswordRequestParams,
) => {
  const response: AxiosResponse<IRestorePasswordResponse> =
    await $api.post<IRestorePasswordResponse>(
      apiEndpoints.authRequestRestorePassword,
      params,
    );
  return response.data;
};

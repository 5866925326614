import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import {
  Input,
  Select,
  DatePicker,
  Radio,
  Upload,
  Button,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import { AddOrderSEO } from './SEO';
import { IFormValuesType } from './types';
import { validationSchema, initialData } from './data';
import styles from './index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

const OrderForm = () => {
  const navigate = useNavigate();

  const initialValues: IFormValuesType = initialData;

  const handleSubmit = (values: IFormValuesType, { setSubmitting }) => {
    console.log(values);
    message.success('Order added successfully');
    setSubmitting(false);
  };

  const handleBackBtnClick = () => {
    navigate(-1);
  };

  return (
    <>
      <AddOrderSEO />
      <div className={styles.formContainer}>
        <h1>Add New Orders</h1>
        <div className={styles.buttonGroup}>
          <Button className={styles.backBtn} onClick={handleBackBtnClick}>
            Back to List
          </Button>
          <Button type="primary">Add New Order</Button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form className={styles.form}>
              <h2>Order Information</h2>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="orderNumber">Order Number</label>
                  <div className={styles.row}>
                    <Field
                      name="orderNumber"
                      as={Input}
                      className={
                        errors.orderNumber && touched.orderNumber
                          ? styles.error
                          : ''
                      }
                    />
                    <Button className={styles.barcodeBtn}>View Barcode</Button>
                  </div>
                </div>
                <ErrorMessage
                  name="orderNumber"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="customerName">Customer Name</label>
                  <Field name="customerName">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        placeholder="Select Customer"
                        className={
                          form.errors.customerName && form.touched.customerName
                            ? styles.error
                            : ''
                        }
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      >
                        <Option value="">Select Customer</Option>
                        <Option value="customer1">Customer 1</Option>
                        <Option value="customer2">Customer 2</Option>
                      </Select>
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="customerName"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="distributorName">Distributor Name</label>
                  <Field
                    name="distributorName"
                    as={Input}
                    className={
                      errors.distributorName && touched.distributorName
                        ? styles.error
                        : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="distributorName"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="authorName">Author Name</label>
                  <Field name="authorName">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        placeholder="Select Author"
                        className={
                          form.errors.authorName && form.touched.authorName
                            ? styles.error
                            : ''
                        }
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      >
                        <Option value="">Select Author</Option>
                        <Option value="author1">Author 1</Option>
                        <Option value="author2">Author 2</Option>
                      </Select>
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="authorName"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="department">Department</label>
                  <Field name="department">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        placeholder="Select Department"
                        className={
                          form.errors.department && form.touched.department
                            ? styles.error
                            : ''
                        }
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      >
                        <Option value="">Select Department</Option>
                        <Option value="department1">Department 1</Option>
                        <Option value="department2">Department 2</Option>
                      </Select>
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="department"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="orderDescription">Order Description</label>
                  <Field
                    name="orderDescription"
                    as={TextArea}
                    className={
                      errors.orderDescription && touched.orderDescription
                        ? styles.error
                        : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="orderDescription"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="styleName">Style Name</label>
                  <Field name="styleName">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        placeholder="Select Style Name, If any"
                        className={
                          form.errors.styleName && form.touched.styleName
                            ? styles.error
                            : ''
                        }
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      >
                        <Option value="">Select Style Name, If any</Option>
                        <Option value="style1">Style 1</Option>
                        <Option value="style2">Style 2</Option>
                      </Select>
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="styleName"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="quantity">Quantity</label>
                  <Field
                    name="quantity"
                    as={Input}
                    type="number"
                    className={
                      errors.quantity && touched.quantity ? styles.error : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="quantity"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="orderDate">Order Date</label>
                  <DatePicker
                    name="orderDate"
                    onChange={date => setFieldValue('orderDate', date)}
                    className={
                      errors.orderDate && touched.orderDate ? styles.error : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="orderDate"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="finishedSewingDate">
                    Finished Sewing Date
                  </label>
                  <DatePicker
                    name="finishedSewingDate"
                    onChange={date => setFieldValue('finishedSewingDate', date)}
                    className={
                      errors.finishedSewingDate && touched.finishedSewingDate
                        ? styles.error
                        : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="finishedSewingDate"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="dueDate">Due Date</label>
                  <DatePicker
                    name="dueDate"
                    onChange={date => setFieldValue('dueDate', date)}
                    className={
                      errors.dueDate && touched.dueDate ? styles.error : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="dueDate"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="sample">Sample, If yes</label>
                  <Field
                    name="sample"
                    as={Input}
                    className={
                      errors.sample && touched.sample ? styles.error : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="sample"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label>Material Supplied by:</label>
                  <Radio.Group
                    name="materialSuppliedBy"
                    onChange={e =>
                      setFieldValue('materialSuppliedBy', e.target.value)
                    }
                  >
                    <Radio value="in-house">In-house</Radio>
                    <Radio value="customer">Customer</Radio>
                  </Radio.Group>
                </div>
                <ErrorMessage
                  name="materialSuppliedBy"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="orderSample">Order Sample</label>
                  <Upload>
                    <Button icon={<UploadOutlined />}>Choose file</Button>
                  </Upload>
                </div>
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="assignOrder">Assign Order</label>
                  <Field name="assignOrder">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        placeholder="Select Level"
                        className={
                          form.errors.assignOrder && form.touched.assignOrder
                            ? styles.error
                            : ''
                        }
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      >
                        <Option value="">Select Level</Option>
                        <Option value="level1">Level 1</Option>
                        <Option value="level2">Level 2</Option>
                      </Select>
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="assignOrder"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <div className={styles.formItemContent}>
                  <label htmlFor="price">Price</label>
                  <Field
                    name="price"
                    as={Input}
                    type="number"
                    addonAfter="USD"
                    className={
                      errors.price && touched.price ? styles.error : ''
                    }
                  />
                </div>
                <ErrorMessage
                  name="price"
                  component="p"
                  className={styles.errorMessage}
                />
              </div>

              <div className={styles.formItem}>
                <Button type="primary" htmlType="submit">
                  Add New
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className={styles.orderDetails}>
          <h2>Order Details</h2>
          <p>
            No any materials added to this order. Click Order Details to add
          </p>
        </div>
      </div>
    </>
  );
};

export default OrderForm;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Select, Input, Button, Space, Card } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { ManagePatternsSEO } from './SEO';

const { Option } = Select;

const getRandomDocFormat = () => {
  const formats = ['.pdf', '.doc', '.xls', '.txt'];
  return formats[Math.floor(Math.random() * formats.length)];
};

const getRandomTenDigitNumber = () => {
  return Math.floor(1000000000 + Math.random() * 9000000000).toString();
};

const ManagePatterns = () => {
  const patternsData = Array(11)
    .fill(null)
    .map((_, index) => ({
      id: index + 1,
      category: index % 2 === 0 ? 'Pant' : 'Shirts',
      pattern: `Pattern ${index + 1}`,
      code: `CODE-${index + 1}`,
      downloadSpec: `${getRandomTenDigitNumber()}_spec${getRandomDocFormat()}`,
      status: 'Active',
    }));

  return (
    <>
      <ManagePatternsSEO />
      <div className={styles.container}>
        <h1 className={styles.title}>Manage Pattern(s)</h1>
        <div className={styles.controls}>
          <Space size="large" className={styles.selectGroup}>
            <Select
              placeholder="Select pattern"
              className={styles.select}
            >
              <Option value="" disabled>
                Select pattern
              </Option>
              <Option value="apron">Apron</Option>
              <Option value="blazer">Blazer</Option>
              <Option value="bolero">Bolero</Option>
              <Option value="coverall">Coverall</Option>
            </Select>
          </Space>
          <Space className={styles.searchGroup}>
            <Input placeholder="Pattern Name" style={{ width: 200 }} />
            <Button type="primary" icon={<SearchOutlined />}>
              Search
            </Button>
          </Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className={styles.addButton}
          >
            Add New Pattern
          </Button>
        </div>
        <div className={styles.patternsGrid}>
          {patternsData.map(pattern => (
            <Card key={pattern.id} className={styles.patternCard}>
              <p>
                <strong>Category:</strong> {pattern.category}
              </p>
              <p>
                <strong>Pattern:</strong> {pattern.pattern}
              </p>
              <p>
                <strong>ode:</strong> {pattern.code}
              </p>
              <p>
                <strong>Download Specification:</strong>{' '}
                <a href="#" className={styles.downloadLink}>
                  {pattern.downloadSpec}
                </a>
              </p>
              <p>
                <strong>Status</strong> {pattern.status}
              </p>
              <Space size="small" className={styles.cardActions}>
                <Button
                  type="default"
                  size="small"
                  icon={<EditOutlined />}
                  className={styles.actionButton}
                >
                  Edit
                </Button>

                <Button
                  type="default"
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  className={styles.actionButton}
                >
                  Delete
                </Button>
              </Space>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default ManagePatterns;

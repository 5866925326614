import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { IRegisterRequestParams, IRegisterResponse } from 'types/api/auth';

export const postRegister = async (params: IRegisterRequestParams) => {
  const response: AxiosResponse<IRegisterResponse> =
    await $api.post<IRegisterResponse>(apiEndpoints.authRequestSignUp, params);
  return response.data;
};

// core
import React, { useEffect } from 'react';
import {
  Space,
  Table,
  Button,
  Input,
  Select,
  Typography,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  MobileOutlined,
  SearchOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';

//hooks

// utils

// locales

// store

// types

// components(atoms)
import { HomeSEO } from './SEO';

// styles
import styles from './index.module.scss';

//data
import { initialValues } from './data';

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

const data = [
  {
    key: '1',
    orderNo: '5218927',
    styleName: '',
    barcode: 'View Barcode',
    orderValue: '11.00 USD',
    customerName: 'Cintas Stock',
    distributor: 'C-FEMALE RECTANGULAR TIE',
    author: 'ANNA',
    qty: 12,
    orderDate: '08-02-2024',
    finishedSewingDate: '00-00-0000',
    dueDate: '09-13-2024',
    currentStatus: 'Waiting for Cutting Department- 00-00-0000',
    currentDepartment: 'Administration',
  },
  {
    key: '2',
    orderNo: '5218883',
    styleName: '',
    barcode: 'View Barcode',
    orderValue: '55.00 USD',
    customerName: 'Cintas Stock',
    distributor: 'C-SAWYER JACKET',
    author: 'ANNA',
    qty: 6,
    orderDate: '08-02-2024',
    finishedSewingDate: '00-00-0000',
    dueDate: '09-13-2024',
    currentStatus: 'Waiting for Cutting Department- 00-00-0000',
    currentDepartment: 'Administration',
  },
  // Add more data items here...
];

const columns = [
  {
    title: 'S.No',
    dataIndex: 'key',
    key: 'key',
    width: 60,
  },
  {
    title: 'Order No - Style Name',
    dataIndex: 'orderNo',
    key: 'orderNo',
    render: (text, record) => (
      <a
        href={`#${text}`}
      >{`${text}${record.styleName ? ` - ${record.styleName}` : ''}`}</a>
    ),
  },
  {
    title: 'Barcode',
    dataIndex: 'barcode',
    key: 'barcode',
    render: text => <Button className={styles.barcodeButton}>{text}</Button>,
  },
  {
    title: 'Order Value',
    dataIndex: 'orderValue',
    key: 'orderValue',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Distributor',
    dataIndex: 'distributor',
    key: 'distributor',
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Order Date',
    dataIndex: 'orderDate',
    key: 'orderDate',
  },
  {
    title: 'Finished Sewing Date',
    dataIndex: 'finishedSewingDate',
    key: 'finishedSewingDate',
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
  },
  {
    title: 'Current Status',
    dataIndex: 'currentStatus',
    key: 'currentStatus',
  },
  {
    title: 'Current Department',
    dataIndex: 'currentDepartment',
    key: 'currentDepartment',
  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <span>
        <Tooltip title="Edit">
          <Button icon={<EditOutlined />} className={styles.actionButton} />
        </Tooltip>
        <Tooltip title="Delete">
          <Button icon={<DeleteOutlined />} className={styles.actionButton} />
        </Tooltip>
        <Tooltip title="Info">
          <Button icon={<MobileOutlined />} className={styles.actionButton} />
        </Tooltip>
      </span>
    ),
  },
];

// func
const Home = () => {
  const navigate = useNavigate();

  const handleAddOrderBtnClick = () => {
    navigate('/add-order'); // Navigate to the "/about" route

  }

  const onSubmit = (values, { setSubmitting }) => {
    console.log('Form values:', values);
    setSubmitting(false);
  };

  return (
    <>
      <HomeSEO />
      <div className={styles.innerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Manage Order(s)</h2>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ setFieldValue, values }) => (
              <Form className={styles.form}>
                <Field name="search">
                  {({ field }) => (
                    <Input
                      {...field}
                      className={styles.searchInput}
                      placeholder="Order Number [or] Ref No [or] Company Name"
                      prefix={<SearchOutlined />}
                    />
                  )}
                </Field>
                <Field name="author">
                  {({ field }) => (
                    <Select
                      {...field}
                      className={styles.selectInput}
                      placeholder="Select Author"
                      onChange={value => setFieldValue('author', value)}
                    >
                      <Select.Option value="" disabled>
                        Select author
                      </Select.Option>
                      <Select.Option value="author1">Author 1</Select.Option>
                      <Select.Option value="author2">Author 2</Select.Option>
                    </Select>
                  )}
                </Field>
                <Field name="department">
                  {({ field }) => (
                    <Select
                      {...field}
                      className={styles.selectInput}
                      placeholder="Select department"
                      onChange={value => setFieldValue('department', value)}
                    >
                      <Select.Option value="" disabled>
                        Select department
                      </Select.Option>
                      <Select.Option value="dept1">Department 1</Select.Option>
                      <Select.Option value="dept2">Department 2</Select.Option>
                    </Select>
                  )}
                </Field>
                <Field name="dateRange">
                  {({ field }) => (
                    <RangePicker
                      {...field}
                      className={styles.dateRangePicker}
                      onChange={value => setFieldValue('dateRange', value)}
                    />
                  )}
                </Field>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<FilterOutlined />}
                >
                  Filter
                </Button>
              </Form>
            )}
          </Formik>
          <Space className={styles.buttonGroup}>
            <Button type="primary">Change Department</Button>
            <Button type="primary">Export Filtered</Button>
            <Button type="primary" onClick={handleAddOrderBtnClick}>
              Add New Order
            </Button>
          </Space>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? styles.evenRow : styles.oddRow
            }
            className={styles.orderTable}
          />
        </div>
      </div>
    </>
  );
};

export default Home;

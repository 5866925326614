// core
import React, { useEffect } from 'react';
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// hooks
import { useQueryFetchProfileInfo } from 'hooks/profile/useQueryFetchProfileInfo';
import { useMutateDeleteProfile } from 'hooks/profile/useMutateDeleteProfile';

// locales
import { useTranslation } from 'react-i18next';

// components(atoms)
import { ProfileSEO } from './SEO';
// import Loader from '../../../components/features/Loader';
// import Button from '../../../components/atoms/Button';

// styles
import styles from './index.module.scss';
import { EButtonTypeType } from 'components/atoms/Button/types';

const { Text, Title } = Typography;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24 }} spin rev={undefined} />
);

const Profile = () => {
  // router hook
  const location = useLocation();
  const query = useUrlQuery();
  const navigate = useNavigate();
  // locales hook
  const { t } = useTranslation();
  //api hooks
  const { data: profileData, isLoading: profileLoading } =
    useQueryFetchProfileInfo();
  const { mutate: deleteProfile, isLoading: deleteProfileLoading } =
    useMutateDeleteProfile();

  const deleteProfileHandler = () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete accont?',
    );
    if (confirmDelete) {
      deleteProfile();
    }
  };

  return (
    <>
      <ProfileSEO />
      <div className={styles.wrapper}>
        <Title level={3}>Profile</Title>
        {(profileLoading || deleteProfileLoading) && (
          <Spin indicator={antIcon} />
        )}
        {profileData && (
          <>
            <Text>
              <Text strong>Email:</Text> {profileData.data.email}
            </Text>
            <Text>
              <Text strong>Name:</Text> {profileData.data.profile.name}{' '}
            </Text>
            {profileData.data.profile.lastName && (
              <Text>
                <Text strong>Last Name:</Text>{' '}
                {profileData.data.profile.lastName}{' '}
              </Text>
            )}
            {profileData.data.profile.phone && (
              <Text>
                <Text strong>Phone:</Text> {profileData.data.profile.phone}{' '}
              </Text>
            )}
            <div className={styles.button}>
              <Button
                type={'primary'}
                onClick={() => {
                  navigate('/user/update-profile');
                }}
              >
                Update Profile
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                type={'primary'}
                onClick={() => {
                  navigate('/user/change-password');
                }}
              >
                Change Password
              </Button>
            </div>
            <div className={styles.button}>
              <Button type={'default'} onClick={deleteProfileHandler}>
                Delete Account
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profile;

import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { ILoginRequestParams, ILoginResponse } from 'types/api/auth';

export const postLogin = async (params: ILoginRequestParams) => {
  const response: AxiosResponse<ILoginResponse> =
    await $api.post<ILoginResponse>(apiEndpoints.authRequestLogin, params);
  return response.data;
};

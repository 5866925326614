import React from 'react';
import { Layout } from 'antd';
import styles from './index.module.scss';

const { Footer } = Layout;

const PageFooter = () => (
  <Footer className={styles.footer}>
    <p className={styles.text}>Developed by MCD Softs</p>
  </Footer>
);

export default PageFooter;

// core
import React, { useEffect, useState } from 'react';
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Typography, Button, Input, Spin } from 'antd';

// hooks
import { useMutateRegister } from 'hooks/auth/useMutateRegister';

// locales
import { useTranslation } from 'react-i18next';

// store

// types
import { EButtonTypeType } from '../../../components/atoms/Button/types';
import { IFormValuesType } from './types';

// components(atoms)
import { SignUpSEO } from './SEO';
import Loader from '../../../components/features/Loader';
// import Button from '../../../components/atoms/Button';

// styles
import styles from './index.module.scss';

// data
import { initialData, signUpValidation } from './data';

const { Text, Title } = Typography;

// func
export default function SignUp() {
  // router hook
  const location = useLocation();
  const navigate = useNavigate();
  const query = useUrlQuery();
  // locales hook
  const { t } = useTranslation();
  //api hooks
  const { mutate, isLoading, isSuccess } = useMutateRegister();

  // local data
  const initialValues: IFormValuesType = initialData;

  const onSignUpHandler = payload => {
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // render
  return (
    <>
      <SignUpSEO />
      <div className={styles.wrapper}>
        {isLoading && <Spin />}

        <Title level={1} className={styles.header__label}>
          {t('loginpage_title')}
        </Title>

        <Formik
          initialValues={initialValues}
          onSubmit={onSignUpHandler}
          validationSchema={signUpValidation}
        >
          {({ errors, touched, setFieldValue, values, handleChange }) => (
            <Form className={styles.form}>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>First Name</Text>
                  <Field
                    component={Input}
                    placeholder="enter first name"
                    type="text"
                    name="name"
                    onChange={e => setFieldValue('name', e.target.value)}
                    className={[
                      styles.form__itemField,
                      errors.name && touched.name ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="firstName" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              {/* <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Field
                    type="text"
                    name="lastName"
                    className={[
                      styles.form__itemField,
                      errors.lastName && touched.lastName ? 'error' : '',
                    ].join()}
                  />
                  <label>Last Name</label>
                </div>
                <ErrorMessage name="lastName" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div> */}

              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Email</Text>
                  <Field
                    component={Input}
                    placeholder="enter email"
                    type="text"
                    autoComplete="chrome-off"
                    name="email"
                    onChange={e => setFieldValue('email', e.target.value)}
                    className={[
                      styles.form__itemField,
                      errors.email && touched.email ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="email" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>

              {/* <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Field
                    type="tel"
                    autoComplete="chrome-off"
                    name="phone"
                    className={[
                      styles.form__itemField,
                      errors.phone && touched.phone ? 'error' : '',
                    ].join()}
                  />
                  <label>Phone</label>
                </div>
                <ErrorMessage name="phone" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div> */}

              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Password</Text>
                  <Field
                    component={Input}
                    placeholder="enter password"
                    type="password"
                    autoComplete="chrome-off"
                    name="password"
                    onChange={e => setFieldValue('password', e.target.value)}
                    className={[
                      styles.form__itemField,
                      errors.password && touched.password ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="password" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>

              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Password Confirmation</Text>
                  <Field
                    component={Input}
                    placeholder="enter password again"
                    type="password"
                    autoComplete="chrome-off"
                    name="passwordConfirmation"
                    onChange={e =>
                      setFieldValue('passwordConfirmation', e.target.value)
                    }
                    className={[
                      styles.form__itemField,
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                        ? 'error'
                        : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage
                  name="passwordConfirm"
                  className={styles.form__errors}
                >
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <Button type={'primary'} htmlType={EButtonTypeType.Submit}>
                Sign Up
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

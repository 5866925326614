import React from 'react';
import { Table, Button, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { ManageAuthorsSEO } from './SEO';

const data = [
  {
    key: '1',
    name: 'author1',
    status: 'Active',
  },
  {
    key: '2',
    name: 'author2',
    status: 'Active',
  },
];

const columns = [
  {
    title: 'S.No',
    dataIndex: 'key',
    key: 'key',
    width: 60,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: styles.nameColumn,
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 120,
  },
  {
    title: 'Action',
    key: 'action',
    width: 120,
    render: (_, record) => (
      <span>
        <Tooltip title="Edit">
          <Button icon={<EditOutlined />} className={styles.actionButton} />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            className={styles.actionButton}
            danger
          />
        </Tooltip>
      </span>
    ),
  },
];

const ManageAuthors = () => {
  return (
    <>
      <ManageAuthorsSEO />
      <div className={styles.container}>
        <h1 className={styles.title}>Manage Author(s)</h1>
        <div className={styles.controls}>
          <Button type="primary" icon={<UserAddOutlined />}>
            Add Author
          </Button>
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className={styles.table}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `Total ${total} items`,
          }}
        />
      </div>
    </>
  );
};

export default ManageAuthors;

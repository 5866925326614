import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  orderNumber: Yup.string().required('Order Number is required'),
  customerName: Yup.string().required('Customer Name is required'),
  distributorName: Yup.string().required('Distributor Name is required'),
  authorName: Yup.string().required('Author Name is required'),
  department: Yup.string().required('Department is required'),
  orderDescription: Yup.string(),
  styleName: Yup.string(),
  quantity: Yup.number().positive().integer().required('Quantity is required'),
  orderDate: Yup.date().required('Order Date is required'),
  finishedSewingDate: Yup.date(),
  dueDate: Yup.date().required('Due Date is required'),
  sample: Yup.string(),
  materialSuppliedBy: Yup.string().required('Material Supplied By is required'),
  assignOrder: Yup.string().required('Assign Order is required'),
  price: Yup.number().positive().required('Price is required'),
});

export const initialData = {
  orderNumber: '',
  customerName: '',
  distributorName: '',
  authorName: '',
  department: '',
  orderDescription: '',
  styleName: '',
  quantity: '',
  orderDate: '',
  finishedSewingDate: '',
  dueDate: '',
  sample: '',
  materialSuppliedBy: 'in-house',
  assignOrder: '',
  price: '',
};

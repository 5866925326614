//[ ] #todo lang
//[ ] #todo toggle lang
//[ ] #todo api lang
//[ ] #todo clear package.json

//[ ] #todo query with pagination
//[ ] #todo courses
//[ ] #todo webinars

import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { queryClient } from '../src/lib/queryClient';

import App from './App';

// General styles
import 'sanitize.css';
import './styles/global.scss';
// import 'antd/dist/antd.css';

// Initialize languages
import './locales/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </QueryClientProvider>,
);

// Hot reloadable translation json files
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

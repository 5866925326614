// core
import React from 'react';

// utils

// locales

// store

// types

// components(atoms)
// import Header from '../../features/Header';

// styles
import styles from './index.module.scss';

// data

// func
const AuthLayout = ({ children }: any) => {
  return (
    <div className={styles.wrapper}>
      {/*header*/}
      {/* <Header /> */}
      <div className={styles.inner}>{children}</div>
      {/*footer>*/}
    </div>
  );
};

export default AuthLayout;

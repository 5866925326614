// core
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConfigProvider } from 'antd';

// api
import { $api } from 'utils/request';

// hooks
import useScrollToTop from './hooks/app/useScrollToTop';
// import useAppTheme from './hooks/app/useAppTheme';

// hoc
import withErrorHandler from 'hoc/withErrorHandler';

// layouts
import AuthLayout from './components/layouts/AuthLayout';
import StaticLayout from './components/layouts/StaticLayout';
import UserLayout from './components/layouts/UserLayout';
import RoutePermissions from './hoc/RoutePermissions';

// components
import Home from 'containers/static/Home';
import AddOrder from 'containers/static/AddOrder';
import CutHistory from 'containers/static/CutHistory';
import ManageUsers from 'containers/static/ManageUsers';
import ManageAuthors from 'containers/static/ManageAuthors';
import ManageDepartments from 'containers/static/ManageDepartments';
import ManageCustomers from 'containers/static/ManageCustomers';
import StockDetails from 'containers/static/StockDetails';
import ManageVendors from 'containers/static/ManageVendors';
import ManageCategories from 'containers/static/ManageCategories';
import ManageOperations from 'containers/static/ManageOperations';
import ManageStyles from 'containers/static/ManageStyles';
import ManagePatterns from 'containers/static/ManagePatterns';

import Profile from 'containers/user/Profile';
import UpdateProfile from 'containers/user/UpdateProfile';
import ChangePassword from 'containers/user/ChangePassword';

import SignUp from 'containers/auth/SignUp';
import Login from 'containers/auth/Login';
import Reset from 'containers/auth/Reset';
import Restore from 'containers/auth/Restore';

// store
import useThemeStore, { appThemes } from 'store/theme';
import { darkTheme, lightTheme } from 'config/colors';

//types
import { ERoutePermissionsType } from './hoc/RoutePermissions/types';

// styles
import styles from './App.module.scss';

function App() {
  // useAppTheme();
  const { appTheme } = useThemeStore();

  useScrollToTop();

  return (
    <ConfigProvider
      theme={{
        token: appTheme === appThemes.dark ? darkTheme : lightTheme,
        components: {
          Button: {
            colorBgContainer:
              appTheme === appThemes.dark
                ? darkTheme.appBackground
                : lightTheme.appBackground,
            colorBorder:
              appTheme === appThemes.dark
                ? darkTheme.colorPrimary
                : lightTheme.colorPrimary,
          },
        },
      }}
    >
      <div
        className={styles.mainWrapper}
        style={{
          background:
            appTheme === appThemes.dark
              ? darkTheme.appBackground
              : lightTheme.appBackground,
        }}
      >
        <Routes>
          {/*auth*/}
          <Route
            path="/auth/sign-up"
            element={
              <AuthLayout>
                <SignUp />
              </AuthLayout>
            }
          />
          <Route
            path="/auth/login"
            element={
              <AuthLayout>
                <Login />
              </AuthLayout>
            }
          />
          <Route
            path="/auth/reset"
            element={
              <AuthLayout>
                <Reset />
              </AuthLayout>
            }
          />
          <Route
            path="/auth/restore"
            element={
              <AuthLayout>
                <Restore />
              </AuthLayout>
            }
          />
          {/*user*/}
          <Route
            path="/user/profile"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <Profile />
                </UserLayout>
              </RoutePermissions>
            }
          />
          <Route
            path="/user/update-profile"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <UpdateProfile />
                </UserLayout>
              </RoutePermissions>
            }
          />
          <Route
            path="/user/change-password"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <ChangePassword />
                </UserLayout>
              </RoutePermissions>
            }
          />
          {/*static*/}
          <Route
            path="*"
            element={
              <StaticLayout>
                <Home />
              </StaticLayout>
            }
          />
          <Route
            path="/home"
            element={
              <StaticLayout>
                <Home />
              </StaticLayout>
            }
          />
          <Route
            path="/add-order"
            element={
              <StaticLayout>
                <AddOrder />
              </StaticLayout>
            }
          />
          <Route
            path="/cut-history"
            element={
              <StaticLayout>
                <CutHistory />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-users"
            element={
              <StaticLayout>
                <ManageUsers />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-authors"
            element={
              <StaticLayout>
                <ManageAuthors />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-departments"
            element={
              <StaticLayout>
                <ManageDepartments />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-customers"
            element={
              <StaticLayout>
                <ManageCustomers />
              </StaticLayout>
            }
          />
          <Route
            path="/stock-details"
            element={
              <StaticLayout>
                <StockDetails />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-vendors"
            element={
              <StaticLayout>
                <ManageVendors />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-categories"
            element={
              <StaticLayout>
                <ManageCategories />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-operations"
            element={
              <StaticLayout>
                <ManageOperations />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-styles"
            element={
              <StaticLayout>
                <ManageStyles />
              </StaticLayout>
            }
          />
          <Route
            path="/manage-patterns"
            element={
              <StaticLayout>
                <ManagePatterns />
              </StaticLayout>
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
      </div>
    </ConfigProvider>
  );
}

export default withErrorHandler(App, $api);

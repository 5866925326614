import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { queryClient } from 'lib/queryClient';
import { postLogin } from 'api/login';
import useAccessTokenStore from 'store/accessToken';

import { ILoginRequestParams, ILoginResponse } from 'types/api/auth';

interface UseLoginOptions
  extends Omit<
    UseMutationOptions<ILoginResponse, unknown, ILoginRequestParams>,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: ILoginResponse) => void;
  onError?: (error: unknown) => void;
}

type UseLoginHook = (
  options?: UseLoginOptions,
) => UseMutationResult<ILoginResponse, unknown, ILoginRequestParams>;

export const useMutateLogin: UseLoginHook = options => {
  const { setAccessToken } = useAccessTokenStore();
  return useMutation(postLogin, {
    ...options,
    onSuccess: data => {
      if (data.accessToken) {
        localStorage.setItem('access_token', data.accessToken);
        setAccessToken(data.accessToken);
        toast.success('Success Login');
        queryClient.invalidateQueries(['fetchProfileInfo']);
      }
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while trying Login';
      console.error(message);
      return message;
    },
  });
};

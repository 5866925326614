import React, { useEffect } from 'react';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';

import useHttpClient from 'hooks/app/httpClient';

const withErrorHandler = (WrappedComponent, $api: AxiosInstance) => props => {
  const [apiError, clearApiError] = useHttpClient($api);

  useEffect(() => {
    if (apiError) {
      toast.error(apiError);
      clearApiError();
    }
  }, [apiError, clearApiError]);

  return <WrappedComponent {...props} />;
};

export default withErrorHandler;

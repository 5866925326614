import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getProfileInfo } from 'api/profile';
import useProfileStore from 'store/profile';
import type { IProfile } from 'types/models/profile';

export const useQueryFetchProfileInfo = (): UseQueryResult<IProfile> => {
  const { setProfile } = useProfileStore();
  return useQuery<IProfile, Error>(['fetchProfileInfo'], getProfileInfo, {
    onSuccess: data => {
      setProfile(data);
    },
    onError: err => {
      const message = err ? err?.message : 'Error while fetching Profile';
      console.error(message);
      return message;
    },
  });
};

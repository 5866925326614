import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { postLogout } from 'api/logout';

import useAccessTokenStore from 'store/accessToken';

export const useMutateLogout = () => {
  const { removeAccessToken } = useAccessTokenStore();

  return useMutation(postLogout, {
    onSuccess: () => {
      removeAccessToken();
      localStorage.removeItem('access_token');
      toast.success('Success Logout');
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while trying Logout';
      console.error(message);
      localStorage.removeItem('access_token');
      return message;
    },
  });
};

import React from 'react';
import { Table, Button, Tooltip, Input } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { ManageVendorsSEO } from './SEO';

const { Search } = Input;

const data = [
  {
    key: '1',
    companyName: 'Ace Binding',
    contactName: 'Larry',
    state: '',
    email: 'none',
    phone: '',
  },
  {
    key: '2',
    companyName: 'Acker & Jablow',
    contactName: 'Robert Steinberg',
    state: 'NY',
    email: 'robert.steinberg@ackerjablow.com',
    phone: '(212) 216-1300 x 328',
  },
  // ... add more vendor data here
];

const columns = [
  {
    title: 'S.No',
    dataIndex: 'key',
    key: 'key',
    width: 60,
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
    className: styles.nameColumn,
  },
  {
    title: 'Contact Name',
    dataIndex: 'contactName',
    key: 'contactName',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: 100,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
  },
  {
    title: 'Action',
    key: 'action',
    width: 120,
    render: (_, record) => (
      <span>
        <Tooltip title="Edit">
          <Button icon={<EditOutlined />} className={styles.actionButton} />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            className={styles.actionButton}
            danger
          />
        </Tooltip>
      </span>
    ),
  },
];

const ManageVendors = () => {
  return (
    <>
      <ManageVendorsSEO />
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Manage Vendor(s)</h1>
          <Button type="primary" icon={<UserAddOutlined />}>
            Add New Vendor
          </Button>
        </div>
        <div className={styles.controls}>
          <Search
            placeholder="Search vendors"
            allowClear
            enterButton={<SearchOutlined />}
            className={styles.search}
          />
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className={styles.table}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `Total ${total} items`,
          }}
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

export default ManageVendors;

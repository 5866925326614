import * as Yup from 'yup';

export const updateProfileValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  phone: Yup.string(),
});

import React from 'react';
import { Select, Input, Button, Space, Card } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { ManageStylesSEO } from './SEO';

const { Option } = Select;

const ManageStyles = () => {
  const stylesData = Array(11)
    .fill(null)
    .map((_, index) => ({
      id: index + 1,
      category: index % 2 === 0 ? 'Pant' : 'Shirts',
      pattern: `Pattern ${index + 1}`,
      patternCode: `CODE-${index + 1}`,
      style: `Style ${index + 1}`,
      customer: 'Unifirst',
      customerPrice: (Math.random() * 100 + 50).toFixed(2),
      downloadSpec: 'BROKEN',
      styleType: 'Active',
    }));

  return (
    <>
      <ManageStylesSEO />
      <div className={styles.container}>
        <h1 className={styles.title}>Manage Style(s)</h1>
        <div className={styles.controls}>
          <Space size="large" className={styles.selectGroup}>
            <Select defaultValue="showAll" style={{ width: 200 }}>
              <Option value="showAll">Show All Categories</Option>
            </Select>
            <Select defaultValue="showAll" style={{ width: 200 }}>
              <Option value="showAll">Show All Patterns</Option>
            </Select>
            <Select defaultValue="showAll" style={{ width: 200 }}>
              <Option value="showAll">Show All Customer's Styles</Option>
            </Select>
          </Space>
          <Space className={styles.searchGroup}>
            <Input placeholder="Style Name" style={{ width: 200 }} />
            <Button type="primary" icon={<SearchOutlined />}>
              Search
            </Button>
          </Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className={styles.addButton}
          >
            Add New Style
          </Button>
        </div>
        <div className={styles.stylesGrid}>
          {stylesData.map(style => (
            <Card key={style.id} className={styles.styleCard}>
              <h3 className={styles.cardTitle}>{style.style}</h3>
              <p>
                <strong>Category:</strong> {style.category}
              </p>
              <p>
                <strong>Pattern:</strong> {style.pattern}
              </p>
              <p>
                <strong>Pattern Code:</strong> {style.patternCode}
              </p>
              <p>
                <strong>Customer:</strong> {style.customer}
              </p>
              <p>
                <strong>Customer Price:</strong> ${style.customerPrice}
              </p>
              <p>
                <strong>Download Specification:</strong>{' '}
                <a href="#" className={styles.downloadLink}>
                  BROKEN
                </a>
              </p>
              <p>
                <strong>Style Type:</strong> {style.styleType}
              </p>
              <Space size="small" className={styles.cardActions}>
                <Button
                  type="default"
                  size="small"
                  className={styles.actionButton}
                >
                  BOM
                </Button>
                <Button
                  type="default"
                  size="small"
                  icon={<EditOutlined />}
                  className={styles.actionButton}
                >
                  Edit
                </Button>
                <Button
                  type="default"
                  size="small"
                  icon={<CopyOutlined />}
                  className={styles.actionButton}
                >
                  Copy
                </Button>
                <Button
                  type="default"
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  className={styles.actionButton}
                >
                  Delete
                </Button>
              </Space>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default ManageStyles;

import * as Yup from 'yup';

export const initialData = {
  name: '',
  // lastName: '',
  email: '',
  // phone: '',
  password: '',
  passwordConfirmation: '',
};

export const signUpValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  // lastName: Yup.string()
  //   .min(2, 'Too Short!')
  //   .max(50, 'Too Long!')
  //   .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  // phone: Yup.string()
  //   .min(6, 'Too Short!')
  //   .max(15, 'Too Long!')
  //   .required('Required'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Required'),
  passwordConfirmation: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
});

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { queryClient } from 'lib/queryClient';
import { postRegister } from 'api/register';

import useAccessTokenStore from 'store/accessToken';
import { IRegisterRequestParams, IRegisterResponse } from 'types/api/auth';
import { toast } from 'react-toastify';

interface UseRegisterOptions
  extends Omit<
    UseMutationOptions<IRegisterResponse, unknown, IRegisterRequestParams>,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: IRegisterResponse) => void;
  onError?: (error: unknown) => void;
}

type UseRegisterHook = (
  options?: UseRegisterOptions,
) => UseMutationResult<IRegisterResponse, unknown, IRegisterRequestParams>;

export const useMutateRegister: UseRegisterHook = options => {
  const { setAccessToken } = useAccessTokenStore();
  return useMutation(postRegister, {
    ...options,
    onSuccess: data => {
      if (data.accessToken) {
        localStorage.setItem('access_token', data.accessToken);
        setAccessToken(data.accessToken);
        toast.success('Success Register');
        queryClient.invalidateQueries(['fetchProfileInfo']);
      }
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while trying Register';
      console.error(message);
      return message;
    },
  });
};

import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';

export const postLogout = async () => {
  const response: AxiosResponse = await $api.post(
    apiEndpoints.authRequestLogout,
  );
  return response;
};

import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { IResetPasswordRequestParams } from 'types/api/auth';

export const postResetPassword = async (
  params: IResetPasswordRequestParams,
) => {
  const response: AxiosResponse<unknown> = await $api.post<unknown>(
    apiEndpoints.authRequestResetPassword,
    params,
  );
  return response.data;
};

import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

type AccessTokenState = {
  accessToken: string | null;
  setAccessToken: (payload: string) => void;
  removeAccessToken: () => void;
};

const useAccessTokenStore = create(
  devtools<AccessTokenState>(
    set => ({
      accessToken: localStorage.getItem('access_token'),
      setAccessToken: (payload: string) => {
        set(state => ({
          accessToken: payload,
        }));
      },
      removeAccessToken: () =>
        set(state => ({
          accessToken: null,
        })),
    }),
    { name: 'accessToken' },
  ),
);
export default useAccessTokenStore;

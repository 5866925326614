import React, { useState } from 'react';
import { Table, Button, Tooltip, Card } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { ManageCustomersSEO } from './SEO';

const data = [
  {
    key: '1',
    companyName: 'Active Breaktime',
    contactName: 'Jeanie Pollack',
    state: 'Illinois',
    email: 'jeaniepollack@gmail.com',
    phone: '',
  },
  {
    key: '2',
    companyName: 'All Tied Up',
    contactName: 'Mary Anne Carius',
    state: 'Illinois',
    email: 'alltiedupstockties@gmail.com',
    phone: '',
  },
  // ... Add more data as needed
];

const ManageCustomers = () => {
  const [showStock, setShowStock] = useState(false);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'key',
      key: 'key',
      width: 60,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span className={styles.actionButtons}>
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} className={styles.editButton} />
          </Tooltip>
          <Tooltip title="Delete">
            <Button icon={<DeleteOutlined />} danger />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      <ManageCustomersSEO />
      <div className={styles.container}>
        <Card className={styles.card}>
          <h1 className={styles.title}>Manage Customer(s)</h1>
          <div className={styles.controls}>
            <div className={styles.buttonGroup}>
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                className={styles.addButton}
              >
                Add Customer
              </Button>
              <Button onClick={() => setShowStock(!showStock)}>
                {showStock ? 'Hide Stock' : 'Show Stock'}
              </Button>
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <Table
              dataSource={data}
              columns={columns}
              className={styles.table}
              pagination={{
                total: data.length,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: total => `Total ${total} items`,
              }}
              scroll={{ x: 'max-content' }}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default ManageCustomers;

// core
import React from 'react';

// utils

// locales

// store

// types

// components(atoms)

// styles
import styles from './index.module.scss';

// data

// func
const Aside = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}></div>
    </div>
  );
};

export default Aside;

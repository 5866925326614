import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

const { Sider } = Layout;

const Sidebar = () => {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth="0"
        className={styles.sidebar}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          onClick={({ key }) => navigate(key)}
        >
          <Menu.Item key="/home" icon={<UserOutlined />}>
            Manage Orders
          </Menu.Item>
          <Menu.Item key="/cut-history" icon={<VideoCameraOutlined />}>
            Cut History
          </Menu.Item>
          <Menu.Item key="/manage-processes" icon={<UploadOutlined />}>
            Manage Processes
          </Menu.Item>
          <Menu.SubMenu
            key="/order-management"
            icon={<SettingOutlined />}
            title="Order Management"
          >
            <Menu.Item key="/order-expired-in-4-days">
              Order Expired in 4 Days
            </Menu.Item>
            <Menu.Item key="/order-expired-in-12-days">
              Order Expired in 12 Days
            </Menu.Item>
            <Menu.Item key="/shipped-orders">Shipped Orders</Menu.Item>
            <Menu.Item key="/closed-orders">Closed Orders</Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/mark-missing-items">Mark Missing Items</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="/manage-quote">Manage Quote</Menu.Item>
          <Menu.Item key="/manage-operations">Manage Operations</Menu.Item>
          <Menu.Item key="/manage-styles">Manage Styles</Menu.Item>
          <Menu.Item key="/manage-patterns">Manage Patterns</Menu.Item>

          <Menu.Divider />
          <Menu.SubMenu
            key="/manage-entities"
            icon={<SettingOutlined />}
            title="Manage Entities"
          >
            <Menu.Item key="/manage-customers">Manage Customers</Menu.Item>
            <Menu.Item key="/stock-details">Stock Details</Menu.Item>
            <Menu.Item key="/manage-vendors">Manage Vendors</Menu.Item>
            <Menu.Item key="/manage-categories">Manage Categories</Menu.Item>
          </Menu.SubMenu>
          <Menu.Divider />
          <Menu.Item key="/manage-users">Manage Users</Menu.Item>
          <Menu.Item key="/manage-authors">Manage Authors</Menu.Item>
          <Menu.Item key="/manage-departments">Manage Departments</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="/reports">Reports</Menu.Item>
          <Menu.Item key="/log-reports">Log Reports</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="/manage-site-page">Manage Site Page</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="/logout" icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Button
        type="primary"
        onClick={toggleCollapsed}
        className={styles.triggerButton}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
    </>
  );
};

export default Sidebar;

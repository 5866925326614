// core
import React, { useEffect } from 'react';
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Typography, Button, Input, Spin } from 'antd';

// hooks
import { useMutateChangePassword } from 'hooks/profile/useMutateChangePassword';

// locales
import { useTranslation } from 'react-i18next';

// types
import { EButtonTypeType } from 'components/atoms/Button/types';
import { IFormValuesType } from './types';

// components(atoms)
import { ChangePasswordSEO } from './SEO';
import Loader from '../../../components/features/Loader';
// import Button from '../../../components/atoms/Button';

// styles
import styles from './index.module.scss';

// data
import { changePasswordValidation, initialData } from './data';

const { Text, Title } = Typography;

const ChangePassword = () => {
  // router hook
  const location = useLocation();
  const query = useUrlQuery();
  const navigate = useNavigate();
  // locales hook
  const { t } = useTranslation();
  //api hooks
  const { mutate, isLoading, isSuccess } = useMutateChangePassword();

  // local data
  const initialValues: IFormValuesType = initialData;

  const onChangePasswordHandler = payload => {
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/user/profile');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <ChangePasswordSEO />
      <div className={styles.wrapper}>
        <Title level={4}>ChangePassword</Title>

        {isLoading && <Spin />}

        <Formik
          initialValues={initialValues}
          onSubmit={onChangePasswordHandler}
          validationSchema={changePasswordValidation}
        >
          {({ errors, touched, setFieldValue, values, handleChange }) => (
            <Form className={styles.form}>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Old Password</Text>
                  <Field
                    component={Input}
                    onChange={e => setFieldValue('oldPassword', e.target.value)}
                    type="password"
                    autoComplete="chrome-off"
                    name="oldPassword"
                    className={[
                      styles.form__itemField,
                      errors.oldPassword && touched.oldPassword ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage
                  name="oldPassword"
                  className={styles.form__errors}
                >
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Password</Text>
                  <Field
                    component={Input}
                    onChange={e => setFieldValue('password', e.target.value)}
                    type="password"
                    autoComplete="chrome-off"
                    name="password"
                    className={[
                      styles.form__itemField,
                      errors.password && touched.password ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="password" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Password Confirmation</Text>
                  <Field
                    component={Input}
                    onChange={e =>
                      setFieldValue('passwordConfirmation', e.target.value)
                    }
                    type="password"
                    autoComplete="chrome-off"
                    name="passwordConfirmation"
                    className={[
                      styles.form__itemField,
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                        ? 'error'
                        : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage
                  name="passwordConfirmation"
                  className={styles.form__errors}
                >
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <Button type="primary" htmlType={EButtonTypeType.Submit}>
                Change
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      window.scrollTo({ left: 0, top: element!.offsetTop, behavior: 'smooth' });
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    }
  }, [location]);
}

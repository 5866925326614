import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Table, Input, DatePicker, Button } from 'antd';
import { CutHistorySEO } from './SEO';

import styles from './index.module.scss';

const data = [
  {
    key: '1',
    date: 'Back',
    time: '-',
    orderNo: '-',
    materialNo: '-',
    batch: '-',
    ydsCut: '-',
    ydsDAamaged: '-',
    user: '-',
  },
  {
    key: '2',
    date: '08-02-2024',
    time: '10:30 AM',
    orderNo: '5218927',
    materialNo: 'C-FEMALE RECTANGULAR TIE',
    batch: 'Batch 1',
    ydsCut: '25',
    ydsDAamaged: '2',
    user: 'ANNA',
  },
  {
    key: '3',
    date: '08-03-2024',
    time: '2:15 PM',
    orderNo: '5218883',
    materialNo: 'C-SAWYER JACKET',
    batch: 'Batch 2',
    ydsCut: '40',
    ydsDAamaged: '1',
    user: 'ANNA',
  },
  {
    key: '4',
    date: '08-04-2024',
    time: '9:45 AM',
    orderNo: '5218890',
    materialNo: 'C-POLO SHIRT',
    batch: 'Batch 3',
    ydsCut: '35',
    ydsDAamaged: '3',
    user: 'ANNA',
  },
];

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Order #',
    dataIndex: 'orderNo',
    key: 'orderNo',
  },
  {
    title: 'Material #',
    dataIndex: 'materialNo',
    key: 'materialNo',
  },
  {
    title: 'Batch',
    dataIndex: 'batch',
    key: 'batch',
  },
  {
    title: 'YDS Cut',
    dataIndex: 'ydsCut',
    key: 'ydsCut',
  },
  {
    title: 'YDS Damaged',
    dataIndex: 'ydsDAamaged',
    key: 'ydsDAamaged',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
  },
];

const CutMaterialHistory = () => {
  return (
    <>
      <CutHistorySEO />
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <h1 className={styles.title}>UserName: Administrator</h1>
            <h2 className={styles.date}>Date: 04 September 2024</h2>
          </div>
          <Button type="primary" className={styles.addNewCutButton}>
            Add new cutting
          </Button>
        </div>

        <Formik
          initialValues={{
            orderNumber: '',
            materialNumber: '',
            batchNumber: '',
            fromDate: '',
            toDate: '',
          }}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {() => (
            <Form className={styles.form}>
              <div className={styles.formFields}>
                <div className={styles.formField}>
                  <label className={styles.label}>Order #:</label>
                  <Field
                    as={Input}
                    name="orderNumber"
                    className={styles.input}
                    placeholder="Enter order number"
                  />
                </div>
                <div className={styles.formField}>
                  <label className={styles.label}>Material #:</label>
                  <Field
                    as={Input}
                    name="materialNumber"
                    className={styles.input}
                    placeholder="Enter material number"
                  />
                </div>
                <div className={styles.formField}>
                  <label className={styles.label}>Batch #:</label>
                  <Field
                    as={Input}
                    name="batchNumber"
                    className={styles.input}
                    placeholder="Enter batch number"
                  />
                </div>
                <div className={styles.formField}>
                  <label className={styles.label}>From date:</label>
                  <Field
                    as={DatePicker}
                    name="fromDate"
                    className={styles.input}
                    placeholder="Select from date"
                  />
                </div>
                <div className={styles.formField}>
                  <label className={styles.label}>To date:</label>
                  <Field
                    as={DatePicker}
                    name="toDate"
                    className={styles.input}
                    placeholder="Select to date"
                  />
                </div>
              </div>
              <div className={styles.formActions}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.searchButton}
                >
                  Search
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        <div className={styles.tableWrapper}>
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? styles.evenRow : styles.oddRow
            }
            className={styles.orderTable}
          />
        </div>
      </div>
    </>
  );
};

export default CutMaterialHistory;

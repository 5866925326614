import React from 'react';

import { Route, Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

// store
import useAccessTokenStore from 'store/accessToken';

//type
import { ERoutePermissionsType, IPropsType } from './types';

export default function RoutePermissions({
  children,
  permissions,
}: IPropsType): React.ReactElement | null {
  let location = useLocation();
  const { accessToken: isAuthenticated } = useAccessTokenStore();
  // auth
  if (permissions.includes(ERoutePermissionsType.Auth) && !isAuthenticated) {
    toast.error('no auth');
    return <Navigate to="/auth/login" state={{ from: location }} />;
  }
  // all permission matched
  return children;
}

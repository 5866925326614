import * as Yup from 'yup';

export const initialData = {
  email: '',
};

export const resetValidation = Yup.object().shape({
  // email: Yup.string().email('Invalid email').required('Required'),
  email: Yup.string().required('Required'),
});

import React from 'react';
import { Table, Button, Tooltip, Input, Select } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import { ManageUsersSEO } from './SEO';

const { Search } = Input;
const { Option } = Select;

const data = [
  {
    key: '1',
    name: 'Administrator',
    employeeId: 'WIPS9129',
    username: 'admin',
    department: '',
    status: 'Active',
  },
  {
    key: '2',
    name: 'USer2',
    employeeId: 'USer2',
    username: 'USer2',
    department: '',
    status: 'Active',
  },
  // Add more user data here...
];

const columns = [
  {
    title: 'S.No',
    dataIndex: 'key',
    key: 'key',
    width: 60,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Employee ID',
    dataIndex: 'employeeId',
    key: 'employeeId',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Department',
    dataIndex: 'department',
    key: 'department',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span>
        <Tooltip title="Edit">
          <Button icon={<EditOutlined />} className={styles.actionButton} />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            className={styles.actionButton}
            danger
          />
        </Tooltip>
      </span>
    ),
  },
];

const ManageUsers = () => {
  return (
    <>
      <ManageUsersSEO />
      <div className={styles.container}>
        <h1 className={styles.title}>Manage User(s)</h1>
        <div className={styles.controls}>
          <Search
            placeholder="Search users"
            className={styles.search}
            allowClear
          />
          <Select defaultValue="all" className={styles.select}>
            <Option value="all">All Departments</Option>
            <Option value="hr">HR</Option>
            <Option value="it">IT</Option>
            <Option value="finance">Finance</Option>
          </Select>
          <Button type="primary" icon={<UserAddOutlined />}>
            Add User
          </Button>
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className={styles.table}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `Total ${total} items`,
          }}
        />
      </div>
    </>
  );
};

export default ManageUsers;

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { queryClient } from 'lib/queryClient';
import { postRestorePassword } from 'api/restorePassword';

import useAccessTokenStore from 'store/accessToken';

import type {
  IRestorePasswordRequestParams,
  IRestorePasswordResponse,
} from 'types/api/auth';

interface UseRestoreOptions
  extends Omit<
    UseMutationOptions<
      IRestorePasswordResponse,
      unknown,
      IRestorePasswordRequestParams
    >,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: IRestorePasswordResponse) => void;
  onError?: (error: unknown) => void;
}

type UseRestoreHook = (
  options?: UseRestoreOptions,
) => UseMutationResult<
  IRestorePasswordResponse,
  unknown,
  IRestorePasswordRequestParams
>;

export const useMutateRestorePassword: UseRestoreHook = options => {
  const { setAccessToken } = useAccessTokenStore();
  return useMutation(postRestorePassword, {
    ...options,
    onSuccess: data => {
      if (data.accessToken) {
        localStorage.setItem('access_token', data.accessToken);
        setAccessToken(data.accessToken);
        toast.success('Success Restore password');
        queryClient.invalidateQueries(['fetchProfileInfo']);
      }
    },
    onError: (err: AxiosError) => {
      const message = err
        ? err?.message
        : 'Error while trying Restore password';
      console.error(message);
      return message;
    },
  });
};

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { deleteProfileAccount } from 'api/profile';

import useAccessTokenStore from 'store/accessToken';

interface UseDeleteProfileOptions
  extends Omit<UseMutationOptions<string>, 'onSuccess' | 'onError'> {
  onSuccess?: (data: string) => void;
  onError?: (error: unknown) => void;
}

type UseDeleteProfileHook = (
  options?: UseDeleteProfileOptions,
) => UseMutationResult<string, unknown, void>;

export const useMutateDeleteProfile: UseDeleteProfileHook = () => {
  const { removeAccessToken } = useAccessTokenStore();

  return useMutation(deleteProfileAccount, {
    onSuccess: () => {
      toast.success('Successfully deleted profile');
      removeAccessToken();
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while deleting Profile';
      console.log(message);
      return message;
    },
  });
};

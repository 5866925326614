// core
import React from 'react';
import { Layout, Typography, Space } from 'antd';
import {
  ClockCircleOutlined,
  UserOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';

// hooks
import { useMutateLogout } from 'hooks/auth/useMutateLogout';
//store
import useAccessTokenStore from 'store/accessToken';

// locales

// types

// styles
import styles from './index.module.scss';

const { Header } = Layout;
const { Title, Text } = Typography;

// func
const WIPTrackerHeader = () => {
  const { accessToken: isAuthenticated } = useAccessTokenStore();
  const { mutate: logout, isLoading, isSuccess } = useMutateLogout();

  const currentDate = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <Header className={styles.header}>
      <div className={styles.headerContent}>
        <Title level={2} className={styles.title}>
          WIP Tracker
        </Title>
        <Space className={styles.userInfo}>
          <Text className={styles.welcomeText}>
            <UserOutlined /> Welcome, Administrator
          </Text>
          <Text className={styles.departmentText}>
            <AppstoreOutlined /> Department: Administration
          </Text>
          <Text className={styles.dateText}>
            <ClockCircleOutlined /> {currentDate}
          </Text>
        </Space>
      </div>
    </Header>
  );
};

export default WIPTrackerHeader;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export function UpdateProfileSEO() {
  // locales hook
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t('containers.updateProfile.seo.title')}</title>
      <meta
        name="description"
        content={t('containers.updateProfile.seo.description')}
      />
    </Helmet>
  );
}

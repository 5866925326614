export const lightTheme = {
  appBackground: '#fff',
  colorPrimary: 'purple',
  colorTextBase: 'purple',
  colorTextLightSolid: 'white',
  colorText: 'black',
  // colorBgContainer: 'white',
  //   fontFamily: 'Montserrat',
};
export const darkTheme = {
  appBackground: '#1C1C1E',
  colorPrimary: '#006666',
  colorTextBase: '#006666',
  colorTextLightSolid: '#DCDCDC',
  colorText: '#808080	',
  // colorBgContainer: '#DCDCDC',
  //   fontFamily: 'Montserrat',
};

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { postResetPassword } from 'api/resetPassword';

import { IResetPasswordRequestParams } from 'types/api/auth';

interface UseResetPasswordOptions
  extends Omit<
    UseMutationOptions<unknown, unknown, IResetPasswordRequestParams>,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: unknown) => void;
  onError?: (error: unknown) => void;
}

type UseResetPasswordHook = (
  options?: UseResetPasswordOptions,
) => UseMutationResult<unknown, unknown, IResetPasswordRequestParams>;

export const useMutateResetPassword: UseResetPasswordHook = options => {
  console.log('useMutateResetPassword options', options);

  return useMutation(postResetPassword, {
    ...options,
    onSuccess: data => {
      toast.success('Success Reset Password');
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while trying Reset Password';
      console.error(message);
      return message;
    },
  });
};

export const apiEndpoints = {
  authRequestSignUp: '/auth/register',
  authRequestLogin: '/auth/login',
  authRequestResetPassword: '/auth/password/reset',
  authRequestRestorePassword: '/auth/password/restore',
  authRequestLogout: '/auth/logout',
  authRequestRefreshToken: '/auth/refresh',

  profileRequestInfo: '/auth/me',
  profileUpdateInfo: '/user/profile',
  profileChangePassword: 'auth/password/change',
  profileDeleteAccount: '/user/delete',
};

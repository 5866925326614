import Locale from 'types/models/locale';

export const DEFAULT_LOCALE = 'en';

export const languagesList: Locale[] = [
  {
    id: 'en',
    short: 'Eng.',
    label: 'English',
    currencySymbol: '$',
  },
  {
    id: 'uk',
    short: 'Укр.',
    label: 'Українська',
    currencySymbol: '₴',
  },
  {
    id: 'ru',
    short: 'Рус.',
    label: 'Русский',
    currencySymbol: '₽',
  },
];

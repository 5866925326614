import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Typography, Button, Input, Spin, Select, Checkbox } from 'antd';

// hooks
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { useMutateLogin } from 'hooks/auth/useMutateLogin';

// types
import { EButtonTypeType } from 'components/atoms/Button/types';
import { IFormValuesType } from './types';

// components(atoms)
import { SignSEO } from './SEO';

// styles
import styles from './index.module.scss';

// data
import { initialData, loginValidation } from './data';

const { Text, Title } = Typography;
const { Option } = Select;

export default function Login() {
  const query = useUrlQuery();
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess } = useMutateLogin();

  const initialValues: IFormValuesType = initialData;

  const onLoginHandler = (payload: IFormValuesType) => {
    console.log(payload);
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/home');
    }
  }, [isSuccess, navigate]);

  return (
    <>
      <SignSEO />
      <div className={styles.wrapper}>
        {isLoading && <Spin />}

        <div className={styles.formContainer}>
          <Title level={1} className={styles.headerLabel}>
            User Login
          </Title>

          <Formik
            initialValues={initialValues}
            onSubmit={onLoginHandler}
            validationSchema={loginValidation}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form className={styles.form}>
                <div className={styles.formItem}>
                  <div className={styles.formItemContent}>
                    <Text>Email</Text>
                    <Field
                      as={Input}
                      placeholder="Enter email"
                      type="text"
                      autoComplete="off"
                      name="email"
                      onChange={e => setFieldValue('email', e.target.value)}
                      className={
                        errors.email && touched.email ? styles.error : ''
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="p"
                    className={styles.errorMessage}
                  />
                </div>
                <div className={styles.formItem}>
                  <div className={styles.formItemContent}>
                    <Text>Password</Text>
                    <Field
                      as={Input}
                      placeholder="Enter password"
                      type="password"
                      autoComplete="off"
                      name="password"
                      onChange={e => setFieldValue('password', e.target.value)}
                      className={
                        errors.password && touched.password ? styles.error : ''
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="p"
                    className={styles.errorMessage}
                  />
                </div>

                <div className={styles.formItem}>
                  <div className={styles.formItemContent}>
                    <Text>Department</Text>
                    <Field name="department">
                      {({ field, form }) => (
                        <Select
                          {...field}
                          placeholder="Select department"
                          className={
                            form.errors.department && form.touched.department
                              ? styles.error
                              : ''
                          }
                          onChange={value =>
                            form.setFieldValue(field.name, value)
                          }
                        >
                          <Option value="" disabled>
                            Select department
                          </Option>
                          <Option value="option1">Option 1</Option>
                          <Option value="option2">Option 2</Option>
                          <Option value="option3">Option 3</Option>
                        </Select>
                      )}
                    </Field>
                  </div>
                  <ErrorMessage
                    name="department"
                    component="p"
                    className={styles.errorMessage}
                  />
                </div>

                <div className={styles.formItem}>
                  <Checkbox>Remember me</Checkbox>
                </div>

                <Button type="primary" htmlType={EButtonTypeType.Submit}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

import * as Yup from 'yup';

export const initialData = {
  password: '',
  passwordConfirmation: '',
};

export const restoreValidation = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Required'),
  passwordConfirmation: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
});

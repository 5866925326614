import * as Yup from 'yup';

export const initialData = {
  email: '',
  password: '',
  department: '',
};

export const loginValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Required'),
  department: Yup.string().required('Required'),
});

import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { IRefreshTokenResponse } from 'types/api/auth';

export const postRefreshToken = async () => {
  const response: AxiosResponse<IRefreshTokenResponse> =
    await $api.post<IRefreshTokenResponse>(
      apiEndpoints.authRequestRefreshToken,
    );
  return response.data;
};

import { $api } from 'utils/request';
import { apiEndpoints } from 'config/api-endpoints';

import type { AxiosResponse } from 'axios';
import type { IProfile } from 'types/models/profile';

import type {
  IUpdateProfileRequestParams,
  IChangePasswordRequestParams,
} from 'types/api/profile';

export const getProfileInfo = async () => {
  const response: AxiosResponse<IProfile> = await $api.get<IProfile>(
    apiEndpoints.profileRequestInfo,
  );
  return response.data;
};

export const updateProfileInfo = async (
  params: IUpdateProfileRequestParams,
): Promise<string> => {
  const response: AxiosResponse<string> = await $api.put<string>(
    apiEndpoints.profileUpdateInfo,
    params,
  );
  return response.data;
};

export const changePassword = async (
  params: IChangePasswordRequestParams,
): Promise<string> => {
  const response: AxiosResponse<string> = await $api.post<string>(
    apiEndpoints.profileChangePassword,
    params,
  );
  return response.data;
};

export const deleteProfileAccount = async (): Promise<string> => {
  const response: AxiosResponse<string> = await $api.delete<string>(
    apiEndpoints.profileDeleteAccount,
  );
  return response.data;
};

// core
import React, { useEffect, useState } from 'react';
import useUrlQuery from '../../../hooks/app/useUrlQuery';
import { Navigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Typography, Button, Input, Spin } from 'antd';

// hooks
import { useMutateResetPassword } from 'hooks/auth/useMutateResetPassword';

// locales
import { useTranslation } from 'react-i18next';

// store

// types
import { EButtonTypeType } from '../../../components/atoms/Button/types';
import { IFormValuesType } from './types';

// components(atoms)
import { ResetPasswordSEO } from './SEO';
import Loader from '../../../components/features/Loader';
// import Button from '../../../components/atoms/Button';

// styles
import styles from './index.module.scss';

// data
import { initialData, resetValidation } from './data';

const { Text, Title } = Typography;

// func
export default function Reset() {
  // router hook
  const location = useLocation();
  const query = useUrlQuery();
  // locales hook
  const { t } = useTranslation();
  //api hooks
  const { mutate, isLoading, isSuccess } = useMutateResetPassword();

  // local data
  const initialValues: IFormValuesType = initialData;

  const onResetHandler = payload => {
    mutate(payload);
  };

  if (isSuccess) {
    console.log('isSuccess', isSuccess);
  }

  // render
  return (
    <>
      <ResetPasswordSEO />
      <div className={styles.wrapper}>
        {isLoading && <Spin />}

        <Title level={1} className={styles.header__label}>
          Reset
        </Title>

        <Formik
          initialValues={initialValues}
          onSubmit={onResetHandler}
          validationSchema={resetValidation}
        >
          {({ errors, touched, setFieldValue, values, handleChange }) => (
            <Form className={styles.form}>
              <div className={styles.form__item}>
                <div className={styles.form__itemContent}>
                  <Text>Email</Text>
                  <Field
                    component={Input}
                    placeholder="enter email"
                    type="text"
                    autoComplete="chrome-off"
                    name="email"
                    onChange={e => setFieldValue('email', e.target.value)}
                    className={[
                      styles.form__itemField,
                      errors.email && touched.email ? 'error' : '',
                    ].join()}
                  />
                </div>
                <ErrorMessage name="email" className={styles.form__errors}>
                  {msg => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <Button type={'primary'} htmlType={EButtonTypeType.Submit}>
                Reset
              </Button>{' '}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

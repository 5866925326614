import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export function ManageDepartmentsSEO() {
  // locales hook
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>Manage departments</title>
      <meta
        name="description"
        content={t('containers.signIn.seo.description')}
      />
    </Helmet>
  );
}
